
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DOMPurify from 'dompurify'
import BtnPrimary from '@/components/parts/btn/BtnPrimary.vue'
import Loader from '@/components/svg/LoaderSVG.vue'
import TriXSLSVG from '@/components/svg/detail/TriXSLSVG.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Loader,
    TriXSLSVG,
    BtnPrimary,
  },

  data: () => ({
    formText: '',
    formErrors: [],
    fName: '',
    lName: '',
    email: '',
    message: '',
    portfolio: '',
    linkedin: '',
    cv: false,
    terms: false,
    loading: false,
    locked: false,
    timeline: null,
  }),
  computed: {
    formInfo() {
      return this.formErrors.length ? this.formErrors[0] : this.formText
    },
    portfolioRequired() {
      if (this.cv === '') return true
      return false
    },
    cvRequired() {
      if (this.portfolio === '') return true
      return false
    },
  },
  methods: {
    validateForm() {
      this.formErrors = []
      const validation = this.$refs.applicantForm
      validation.validate().then(() => {
        if (validation.flags.invalid) {
          validation.$children.forEach((child) => {
            if (child.value) {
              child.errors.forEach((error) => {
                this.formErrors.push(error)
              })
            }
          })
        }
      })
    },

    handleFileChange(event) {
      if (event.target.files[0]) {
        // validate field
        this.$refs.cvUpload.validate(event).then((file) => {
          if (file.valid) {
            this.cv = true
            this.cvFilename = event.target.files[0].name
            this.validateForm()
          } else {
            this.cv = false
            this.formErrors.push(file.errors[0])
          }
        })
      }
    },

    async getCaptchaToken() {
      this.loading = true
      try {
        const token = await this.$recaptcha.execute('contacts_submit')
        const formData = new FormData()

        formData.append('firstName', DOMPurify.sanitize(this.fName))
        formData.append('lastName', DOMPurify.sanitize(this.lName))
        formData.append('email', DOMPurify.sanitize(this.email))
        formData.append('message', DOMPurify.sanitize(this.message))

        formData.append('gToken', token)

        this.$api
          .post(`${this.$config.API_URL}/api/contacts`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(() => {
            this.formText = 'Message Received'
            this.loading = false
            this.locked = true
          })
          .catch((error) => {
            console.error(error, error.response)
            if (error.response) {
              this.formErrors.push(`${error.response.statusText || error.response.data.error.message} ${error.response.status}`)
            } else if (error.request) {
              this.formErrors.push('Network error')
            } else {
              this.formErrors.push(`${error.message}`)
            }
            this.loading = false
            this.locked = true
          })
      } catch (e) {
        this.formErrors.push(e.message)
        this.loading = false
        this.locked = true
      }
    },

    // initAnimations() {
    //   this.timeline = this.$gsap.timeline()
    //   const slideUp = { opacity: 0, yPercent: 100, duration: 0.2 }
    //   this.timeline.from(this.$refs.nameRef, slideUp, '>')
    //   this.timeline.from(this.$refs.emailRef, slideUp, '>')
    //   this.timeline.from(this.$refs.portfolioRef, slideUp, '>')
    //   this.timeline.from(this.$refs.cvRef, slideUp, '>')
    //   this.timeline.from(this.$refs.termsRef, slideUp, '>')
    //   this.timeline.from(this.$refs.buttonRef, slideUp, '>')
    // },
  },
}
