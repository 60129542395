
import { mapGetters } from 'vuex'
import BtnSocial from '@/components/parts/btn/BtnSocial.vue'
export default {
  components: { BtnSocial },

  computed: {
    ...mapGetters({
      socials: 'getSocials',
    }),
  },
}
