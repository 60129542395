
import { mapGetters } from 'vuex'
import { parse } from 'marked'
import HeadingSM from '@/components/wrappers/HeadingSM.vue'
import LogoRingSVG from '@/components/svg/LogoRingSVG.vue'
import LineLongSVG from '@/components/svg/detail/LineLongSVG.vue'
import HorizontalSVG from '@/components/svg/detail/HorizontalSVG.vue'
import HorizontalShortSVG from '@/components/svg/detail/HorizontalShortSVG.vue'
import Socials from '@/components/parts/Socials.vue'
import ContactForm from '@/components/parts/contact/ContactForm.vue'
import Saw from '@/components/parts/Saw.vue'
export default {
  components: {
    HeadingSM,
    LogoRingSVG,
    LineLongSVG,
    Saw,
    HorizontalSVG,
    HorizontalShortSVG,
    Socials,
    ContactForm,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tl: null,
    }
  },
  computed: {
    ...mapGetters({
      footer: 'getFooter',
    }),
    renderedBody() {
      return this.footer.body ? parse(this.footer.body) : ''
    },
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        // markers: true,
        // toggleActions: 'play reset play reverse',
        once: true,
      },
    })

    if (this.$refs.heading) {
      const heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines' })
      this.tl.from(heading.chars, {
        opacity: 0,
        stagger: {
          each: 0.1,
          from: 'start',
          grid: 'auto',
          ease: 'power2.inOut',
        },
      })
    }

    this.$refs.preheading && this.tl.from(this.$refs.preheading.$el, { opacity: 0, xPercent: -10, duration: 0.2 }, '>')

    this.tl.from(this.$refs.body, { opacity: 0, duration: 0.2 }, '>')
    this.tl.from(this.$refs.socials.$refs.social, { opacity: 0, stagger: 0.05 }, '>')

    this.tl.from(this.$refs.copy, { opacity: 0, duration: 0.3 }, '<')
    this.tl.from(this.$refs.nav2, { opacity: 0, duration: 0.3 }, '<')
    this.tl.from(this.$refs.nav3.$el, { opacity: 0, duration: 0.3 }, '>')
    this.tl.from(this.$refs.logo.$el, {
      opacity: 0,
      rotate: -10,
      duration: 0.3,
    })
    this.tl.from(this.$refs.svg1.$el.children, { drawSVG: 0, duration: 1 }, '>')
    this.tl.from(this.$refs.svg2.$el.children, { drawSVG: 0, duration: 1 }, '>')
    this.tl.from(this.$refs.svg3.$el.children, { drawSVG: 0, duration: 1 }, '>')
  },
}
